<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Angular UX</a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <!-- <nb-action class="control-item">
    <nb-search type="rotate-layout" (search)="search($event)"></nb-search>
  </nb-action>

  <button (click)="search('test')">Test button</button> -->
  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline" link="contact"></nb-action>
    <nb-action class="control-item" icon="question-mark" link="contact" text="about" nbTooltip="About"
      nbTooltipPlacement="top"></nb-action>
    <!--<nb-action class="user-action" *nbIsGranted="['view', 'user']">
       <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="user?.picture"
      >
      </nb-user> -->

    <!-- <nb-user [onlyPicture]="userPictureOnly" [name]=" " [picture]="user?.picture"> 
      </nb-user>
    </nb-action>-->
  </nb-actions>
</div>
