import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "./@core/utils/analytics.service";
import { SeoService } from "./@core/utils/seo.service";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { HttpClient } from "@angular/common/http";
import { Router, NavigationEnd } from "@angular/router";
import { filter, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  private emailSent = false;
  private routeSubject = new Subject<string>();

  constructor(
    private analytics: AnalyticsService,
    private seoService: SeoService,
    private fireAnalytics: AngularFireAnalytics,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.fireAnalytics.logEvent("user_visit");
    if (!this.emailSent) {
      //this.newUserVisit();
      this.emailSent = true;
    }
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.routeSubject.next(event.urlAfterRedirects);
      });

    // Debounce route changes and then send email
    this.routeSubject
      .pipe(
        debounceTime(1000) // 2 seconds debounce time
      )
      .subscribe((route) => {
        this.sendRouteEmail(route);
      });
  }

  private sendRouteEmail(route: string) {
    const routeInfo = this.collectUserInfo(route);
    this.sendEmail(routeInfo);
  }

  private collectUserInfo(url: string) {
    return {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      pageUrl: url,
    };
  }

  private sendEmail(info: any) {
    this.httpClient
      .post(
        "https://us-central1-angularux.cloudfunctions.net/sendUserVisitEmail",
        info
      )
      .subscribe({
        next: (response) => {
          console.log("Firebase Function triggered:", response);
        },
        error: (error) => {
          console.error("Failed to trigger Firebase Function:", error);
        },
      });
  }
}
