import { NbMenuItem } from "@nebular/theme";

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: "E-commerce Dashboard",
    icon: "shopping-cart-outline",
    link: "/pages/dashboard",
    home: true,
  },
  {
    title: "IoT Dashboard",
    icon: "home-outline",
    link: "/pages/iot-dashboard",
  },
  {
    title: "FEATURES",
    group: true,
  },
  {
    title: "Animations",
    expanded: false,
    icon: "film-outline",
    children: [
      {
        title: "Particle",
        link: "/pages/animations/particles",
      },
      {
        title: "Keyframe",
        link: "/pages/animations/keyframes",
      },
      {
        title: "Animated Searches",
        link: "/pages/ui-features/search-fields",
      },
      {
        title: "Buttons",
        link: "/pages/animations/buttons",
      },
      {
        title: "Scroll Google Slides",
        link: "/pages/animations/scroll",
      },
    ],
  },
  {
    title: "AI Components",
    icon: "settings",
    children: [
      {
        title: "Face Recognition",
        link: "/pages/ai/facialRecognition",
      },
      {
        title: "Live Cam Classification",
        link: "/pages/ai/webcamClassification",
      },
      {
        title: "Image Classification",
        link: "/pages/ai/imageClassification",
      },
      {
        title: "ChatGPT",
        link: "/pages/ai/gpt",
      },
      {
        title: "Embeddings - Resume",
        link: "/pages/ai/gptEmbeddings",
      },
      {
        title: "Embeddings - Career Coach",
        link: "/pages/ai/careerCoach",
      },
      {
        title: "Sentiment Analysis",
        link: "/pages/ai/sentimentAnalysis",
      },
      {
        title: "Time Series Forcasting",
        link: "/pages/ai/timeSeriesForcasting",
      },
      {
        title: "Speech Recognition",
        link: "/pages/ai/speechRecognition",
      },
      {
        title: "Anonomly Detection",
        link: "/pages/ai/anonomlyDetection",
      },
      {
        title: "(XAI) Explainable AI",
        link: "/pages/ai/xai",
      },
      {
        title: "Reinforcement Learning",
        link: "/pages/ai/reinforcementLearning",
      },
      {
        title: "Generative Adversarial Networks",
        link: "/pages/ai/gans",
      },
      // {
      //   title: 'Natural Language Generation',
      //   link: '/pages/ai/nlg',
      // }
    ],
  },
  {
    title: "Layouts",
    icon: "layout-outline",
    children: [
      {
        title: "Stepper",
        link: "/pages/layout/stepper",
      },
      {
        title: "Breadcrumbs",
        link: "/pages/layout/breadcrumb",
      },
      {
        title: "Organization Chart",
        link: "/pages/layout/orgChart",
      },
      {
        title: "Dock",
        link: "/pages/layout/dock",
      },
      {
        title: "Mega Menu",
        link: "/pages/layout/megaMenu",
      },
      {
        title: "Split Screen",
        link: "/pages/layout/splitScreen",
      },
      {
        title: "List",
        link: "/pages/layout/list",
      },
      {
        title: "Infinite List",
        link: "/pages/layout/infinite-list",
      },
      {
        title: "Accordion",
        link: "/pages/layout/accordion",
      },
      {
        title: "Tabs",
        pathMatch: "prefix",
        link: "/pages/layout/tabs",
      },
    ],
  },
  {
    title: "Forms",
    icon: "edit-2-outline",
    children: [
      {
        title: "Form Inputs",
        link: "/pages/forms/inputs",
      },
      {
        title: "Form Layouts",
        link: "/pages/forms/layouts",
      },
      {
        title: "Buttons",
        link: "/pages/forms/buttons",
      },
      {
        title: "Datepicker",
        link: "/pages/forms/datepicker",
      },
    ],
  },
  {
    title: "UI Features",
    icon: "keypad-outline",
    link: "/pages/ui-features",
    children: [
      {
        title: "Grid",
        link: "/pages/ui-features/grid",
      },
      {
        title: "Icons",
        link: "/pages/ui-features/icons",
      },
      {
        title: "Typography",
        link: "/pages/ui-features/typography",
      },
      {
        title: "Animated Searches",
        link: "/pages/ui-features/search-fields",
      },
    ],
  },
  {
    title: "Modal & Overlays",
    icon: "browser-outline",
    children: [
      {
        title: "Dialog",
        link: "/pages/modal-overlays/dialog",
      },
      {
        title: "Window",
        link: "/pages/modal-overlays/window",
      },
      {
        title: "Popover",
        link: "/pages/modal-overlays/popover",
      },
      {
        title: "Toastr",
        link: "/pages/modal-overlays/toastr",
      },
      {
        title: "Tooltip",
        link: "/pages/modal-overlays/tooltip",
      },
    ],
  },
  {
    title: "Extra Components",
    icon: "message-circle-outline",
    children: [
      {
        title: "Calendar",
        link: "/pages/extra-components/calendar",
      },
      {
        title: "Progress Bar",
        link: "/pages/extra-components/progress-bar",
      },
      {
        title: "Spinner",
        link: "/pages/extra-components/spinner",
      },
      {
        title: "Alert",
        link: "/pages/extra-components/alert",
      },
      {
        title: "Calendar Kit",
        link: "/pages/extra-components/calendar-kit",
      },
      {
        title: "Chat",
        link: "/pages/extra-components/chat",
      },
    ],
  },
  {
    title: "Maps",
    icon: "map-outline",
    children: [
      {
        title: "Google Maps",
        link: "/pages/maps/gmaps",
      },
      {
        title: "Leaflet Maps",
        link: "/pages/maps/leaflet",
      },
      {
        title: "Bubble Maps",
        link: "/pages/maps/bubble",
      },
      {
        title: "Search Maps",
        link: "/pages/maps/searchmap",
      },
    ],
  },
  {
    title: "Charts",
    icon: "pie-chart-outline",
    children: [
      {
        title: "Echarts",
        link: "/pages/charts/echarts",
      },
      {
        title: "Charts.js",
        link: "/pages/charts/chartjs",
      },
      {
        title: "D3",
        link: "/pages/charts/d3",
      },
    ],
  },
  {
    title: "Editors",
    icon: "text-outline",
    children: [
      {
        title: "TinyMCE",
        link: "/pages/editors/tinymce",
      },
      {
        title: "CKEditor",
        link: "/pages/editors/ckeditor",
      },
    ],
  },
  {
    title: "Tables & Data",
    icon: "grid-outline",
    children: [
      {
        title: "Smart Table",
        link: "/pages/tables/smart-table",
      },
      {
        title: "Tree Grid",
        link: "/pages/tables/tree-grid",
      },
    ],
  },
  {
    title: "Miscellaneous",
    icon: "shuffle-2-outline",
    children: [
      {
        title: "404",
        link: "/pages/miscellaneous/404",
      },
      {
        title: "Patterns Playground",
        link: "/pages/miscellaneous/ngrxPlayground",
      },
    ],
  },
  {
    title: "Auth",
    icon: "lock-outline",
    children: [
      {
        title: "Login",
        link: "/auth/login",
      },
      {
        title: "Register",
        link: "/auth/register",
      },
      {
        title: "Request Password",
        link: "/auth/request-password",
      },
      {
        title: "Reset Password",
        link: "/auth/reset-password",
      },
    ],
  },
  {
    title: "Contact",
    icon: "email-outline",
    link: "/pages/contact",
  },
];
