import { Component, ElementRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" (click)="onMenuItemClick($event)" responsive>
        <ng-content select="nb-menu" class="single"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  isScreenSizeLessThan500: boolean;

  constructor(
    private sidebarService: NbSidebarService,
    private breakpointService: NbMediaBreakpointsService,
    private elementRef: ElementRef
  ) {
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.isScreenSizeLessThan500 = window.innerWidth < xl;

    window.addEventListener('resize', () => {
      this.isScreenSizeLessThan500 = window.innerWidth < xl;
    });
  }

  onMenuItemClick(event: any) {
    const clickedElement = event.target;
    if (clickedElement.currentTarget !== 'rect' && this.isScreenSizeLessThan500) {
      // If the click was on the right half of the viewport, don't toggle the sidebar
      if (event.clientX > window.innerWidth / 2) {
        return;
      }
      console.log('Menu item clicked:', event);
      // Perform additional actions or logic for menu item clicks
      this.toggleSidebar();
    }
  }


  toggleSidebar(): boolean {
    if (this.isScreenSizeLessThan500) {
      console.log('toggleSidebar()');
      this.sidebarService.toggle(true, 'menu-sidebar');
    }

    return false;
  }
}
