import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {

    private static promise: Promise<void>;

    public static load() {
        // Only load the script once
        if (!this.promise) {
            this.promise = new Promise<void>((resolve) => {
                window['__onGoogleMapsApiLoaded'] = () => { resolve(); };

                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCKegMgutfO0nPb25wBTKu58ktkOHpWxaQ&libraries=places&callback=__onGoogleMapsApiLoaded';
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
            });
        }

        return this.promise;
    }
}
